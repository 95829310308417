<template>
    <article class="columns">
      <section class="column">
        <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
        <div class="modal-background"></div>
        <div class="modal-card scrollable" style="width: auto">
            <!-- <form action="" class="box" @submit.prevent="submitForm"> -->
            <header class="modal-card-head">
                <span class="modal-card-title has-text-centered">
                    {{ (addingNewProduct ? "ADD NEW PRODUCT" : "UPDATE PRODUCT") }} 
                </span>
                <b-button class="intro-show-button"  id="intro-show-button-icon" @click="forceIntro(introSteps)">
                    <b-icon
                        pack="fas"
                        icon="lightbulb"
                        type="is-warning"                    
                        size="is-small">
                    </b-icon>
                </b-button>
            <button class="delete is-danger" type="button" @click="cancelForm">Cancel</button>
            </header>
            <section class="modal-card-body">
                <div class="columns">
                    <div class="column is-6 has-text-weight-bold" v-if="scannedProduct.upc">
                        <form ref="form" @submit.prevent="handleSubmit">
                            <div class="columns is-mobile veecli-table bottom-line" id="intro-pbuf-upc">
                                <div class="column is-4 has-text-weight-bold has-text-left">
                                    UPC
                                </div>
                                <div class="column is-8 has-text-left">
                                    {{ scannedProduct.upc}}-{{ zeroPad(scannedProduct.upcModifier,3) }}
                                    <span v-if="!addingNewProduct">
                                        <span v-if="scannedProduct.active">
                                            🟢
                                        </span>
                                        <span v-else>
                                            🔴
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="columns is-mobile veecli-table bottom-line" id="intro-pbuf-sku" v-if="scannedProduct.sku">
                                <div class="column is-3 has-text-weight-bold has-text-left">
                                    SKU
                                </div>
                                <div class="column is-9">
                                    {{ scannedProduct.sku }}
                                </div>
                            </div>
                            <div class="columns is-mobile veecli-table-input bottom-line" id="intro-pbuf-name">
                                <div class="column is-4 has-text-weight-bold has-text-left">
                                    Name
                                </div>
                                <div class="column is-8">
                                    <span>
                                        <VeeInput
                                            refname="productName"
                                            ref="productName"
                                            name="scannedProduct.name"
                                            type="text"
                                            v-model="scannedProduct.name"
                                            :value="scannedProduct.name"
                                            width="15em"
                                            :required="true"
                                            :editable="allowEdit"
                                            >
                                        </VeeInput>
                                        <!-- v-if="addingNewProduct" -->
                                        <!-- <span v-else>
                                            {{ scannedProduct.name }}
                                        </span> -->
                                    </span>
                                </div>
                            </div>                                                        
                            <div class="columns is-mobile bottom-line"  id="intro-pbuf-dept">
                                <div class="column is-4 has-text-weight-bold">
                                    Department
                                </div>
                                <div class="column is-8 has-text-left">
                                    <b-select class="veecli-select" 
                                        @input="departmentChanged"
                                        v-model="scannedProduct.departments">
                                        <option v-for="item in departments" 
                                            :key="item.id" 
                                            :value="item">
                                                {{ item.name }}
                                        </option>
                                    </b-select>
                                    <!-- v-if="addingNewProduct" :disabled="!addingNewProduct"
                                    <span v-else>
                                        {{ (scannedProduct.departments ? scannedProduct.departments.name : "") }}
                                    </span> -->
                                </div>
                            </div>

                            <div class="columns is-mobile veecli-table bottom-line" id="intro-pbuf-foodstamp">
                                <div class="column is-8 has-text-left">
                                    Food Stamp Allowed
                                </div>
                                <div class="column is-4 has-text-left">
                                    <b-switch 
                                        size="is-small"
                                        v-model="scannedProduct.foodStamp">
                                    </b-switch>
                                </div>                          
                                <!-- <div class="column">
                                    Sp. Discount                                
                                    <b-switch 
                                        size="is-small"
                                        v-model="scannedProduct.specialDiscount">
                                    </b-switch>
                                </div> -->
                            </div>
                            
                            <div class="columns is-mobile bottom-line">
                                <div class="column" id="intro-pbuf-taxrates">
                                    Tax Rates
                                    <!-- <a href="#" @click="clearTaxRates" v-show="taxRates.length > 0">
                                        <b-icon
                                            type="is-danger"
                                            icon="far fa-trash"
                                            size="is-small">
                                        </b-icon>
                                    </a> -->

                                    <b-button class="table-clear-button" 
                                        v-if="taxRates.length > 0"
                                        title="Clear Selection"      
                                        @click="clearTaxRates">
                                        <b-icon
                                            icon="far fa-trash"
                                            type="is-primary"                                                           
                                            size="is-small">
                                        </b-icon>
                                    </b-button>
                                    <!-- <b-button
                                        class="thin-button"
                                        rounded
                                        outlined
                                        type="is-primary"
                                        icon-left="far fa-trash"
                                        v-if="taxRates.length > 0"
                                        size="is-small"
                                        @click="clearTaxRates"/> -->
                                    <b-select
                                        class="multi-select"
                                        multiple
                                        size="is-small"
                                        native-size="2"
                                        v-model="taxRates">
                                        <option
                                            v-for="taxRate in posConfig.taxRates"
                                            :value="taxRate.id"
                                            :key="taxRate.id">
                                            {{ taxRate.name }} - {{ taxRate.rate }}%
                                        </option>
                                    </b-select> 

                                </div>
                                <div class="vertical-line"></div>
                                <div class="column" id="intro-pbuf-idcheck">
                                    Age Validations 
                                    <!-- <a href="#" @click="clearIdChecks" v-if="idChecks.length > 0">
                                        <b-icon
                                            type="is-danger"
                                            icon="far fa-trash"
                                            size="is-small">
                                        </b-icon>
                                    </a> -->
                                    <!-- <b-button
                                        class="thin-button"
                                        rounded
                                        outlined
                                        type="is-primary"
                                        icon-left="far fa-trash"
                                        v-if="idChecks.length > 0"
                                        size="is-small"
                                        @click="clearIdChecks"/> -->

                                    <b-button class="table-clear-button" 
                                        v-if="idChecks.length > 0" 
                                        title="Clear Selection"    
                                        @click="clearIdChecks">
                                        <b-icon
                                            icon="far fa-trash"
                                            type="is-primary"                                                           
                                            size="is-small">
                                        </b-icon>
                                    </b-button>
                                    <b-select
                                        class="multi-select"
                                        multiple
                                        size="is-small"
                                        native-size="2"
                                        v-model="idChecks">
                                        <option
                                            v-for="ageItem in posConfig.ageValidations"
                                            :value="ageItem.id"
                                            :key="ageItem.id">
                                            {{ ageItem.name }}
                                        </option>
                                    </b-select>
                                </div>
                            </div>
                            <!-- <div class="columns is-mobile">
                                <div class="column is-6">
                                     Blue Law {{ scannedProduct.blueLawIdArr }}
                                    <b-select
                                        multiple
                                        size="is-small"
                                        native-size="3"
                                        v-model="scannedProduct.blueLawIdArr">
                                        <option
                                            v-for="blueLaw in posConfig.blueLaws"
                                            :value="blueLaw.id"
                                            :key="blueLaw.id">
                                            {{ blueLaw.name }} 
                                        </option>
                                    </b-select>
                                </div>
                            </div> -->
                            <!-- <div class="columns is-mobile veecli-table-input  has-text-weight-bold">
                                <div class="column is-6">
                                {{ scannedProduct.blueLawSysIds }}
                                </div>
                            </div>

                            <div class="columns is-mobile veecli-table-input  has-text-weight-bold">
                                <div class="column is-6">
                                {{  scannedProduct.fees }}
                                </div>
                            </div> -->

                            <!-- <div class="columns spacer-row is-hidden-mobile">
                                <div class="column has-text-white">
                                    ------------------------------------------------------------------
                                </div>
                            </div>                                                                                    -->
                        </form>
                    </div>
                    <div class="vertical-line"></div>
                    <div class="column is-6">
                        <!-- <div class="columns veecli-table  has-text-weight-bold">
                            <div class="column is-12 is-size-6">
                                Profit Method - {{ getSetting('profitCalculationMethod') }} - 
                                {{ deptProfitPercentage }}% of {{ (getSetting('profitCalculationMethod') == 'Markup') ? "Cost" : "Sales Price" }}.
                            </div>
                        </div> -->

                        <!-- <div class="columns is-mobile">
                            <div class="column is-12 has-text-weight-bold has-text-centered is-size-5">
                                Suggested Price
                            </div>
                        </div> -->
                                                
                        <div class="columns is-mobile veecli-table-input bottom-line"  id="intro-pbuf-calcost" v-if="!scannedProduct.averageCost">
                            <div class="column is-9 has-text-weight-bold is-size-6" v-if="getSetting('profitCalculationMethod') == 'Markup'">
                                Calculated Cost ({{ deptProfitPercentage }}% of Cost)
                            </div>
                            <div class="column is-9 has-text-weight-bold is-size-6" v-else>
                                Calculated Cost ({{ deptProfitPercentage }}% of Price)
                            </div>
                            <div class="column is-3 has-text-right has-text-weight-bold">
                                {{ priceToCost | formatCurrency }}
                            </div>                                
                        </div> 

                        <div class="columns is-mobile veecli-table bottom-line"  id="intro-pbuf-suggprice" v-if="getSetting('profitCalculationMethod') == 'Markup' && scannedProduct.averageCost">
                            <div class="column is-9 has-text-weight-bold is-size-6">
                                Suggested Price ({{ deptProfitPercentage }}%)

                                <b-button class="table-clear-button" 
                                    v-if="scannedProduct.averageCost"
                                    title="Copy Suggested Price"      
                                    @click="copyToPrice('markup')">
                                    <b-icon
                                        icon="far fa-arrow-down"
                                        type="is-primary"                                                           
                                        size="is-small">
                                    </b-icon>
                                </b-button>

                                <b-icon type="is-success" icon="check" v-if="getSetting('profitCalculationMethod') == 'Markup'"></b-icon>
                            </div>
                            <div class="column is-3 has-text-right has-text-weight-bold">
                                {{suggestedMarkupPrice | formatCurrency}}
                            </div>                            
                        </div>
                        <div class="columns is-mobile veecli-table bottom-line" id="intro-pbuf-suggprice" v-if="getSetting('profitCalculationMethod') == 'Margin' && scannedProduct.averageCost">
                            <div class="column is-9 has-text-weight-bold is-size-6">
                                Suggested Price ({{ deptProfitPercentage }}%)

                                    <b-button class="table-clear-button" 
                                        v-if="scannedProduct.averageCost"
                                        title="Copy Suggested Price"      
                                        @click="copyToPrice('margin')">
                                        <b-icon
                                            icon="far fa-arrow-down"
                                            type="is-primary"                                                           
                                            size="is-small">
                                        </b-icon>
                                    </b-button>

                                <b-icon type="is-success" icon="check" v-if="getSetting('profitCalculationMethod') == 'Margin'"></b-icon>
                            </div>
                            <div class="column is-3 has-text-right has-text-weight-bold">
                                {{suggestedMarginPrice | formatCurrency}}
                            </div>                            
                        </div>

                        <div class="columns is-mobile veecli-table-input bottom-line" id="intro-pbuf-cost">
                            <div class="column is-8 is-size-6 has-text-weight-bold">
                                Actual Purchase Cost
                            </div>
                            <div class="column is-4 has-text-right">
                                <VeeInput
                                    type="currency" 
                                    v-model="scannedProduct.averageCost"
                                    name="averageCost"
                                    :value="scannedProduct.averageCost"
                                    :countryCurrencyCode="countryCurrencyCode"
                                    :editable="allowEdit && scannedProduct.upc != ''"
                                    :required="false"
                                    />
                            </div>                                
                        </div>

                        <div class="columns is-mobile has-text-weight-bold current-price" id="intro-pbuf-currprice">
                            <div class="column is-8">
                                Current Price (
                                <span
                                :class="{'bad-price' : currentMarginPerc() < 0,  'has-text-warning-dark' : newMarginPerc() >= 0}">
                                {{ currentMarginPerc() | formatNumber }}%
                                </span>)
                            </div>                                                                
                            <div class="column has-text-right is-4">
                                <span
                                :class="{'bad-price' : currentMarginPerc() < 0,  'has-text-warning-dark' : newMarginPerc() >= 0}">
                                    {{ (scannedProduct.priceCurrent ? scannedProduct.priceCurrent : 0) | formatCurrency}}
                                </span>
                            </div>
                        </div>                        

                        <div class="columns is-mobile has-text-weight-bold new-price" id="intro-pbuf-price">
                            <div class="column is-8" >
                                New Price (
                                <span
                                :class="{'bad-price' : newMarginPerc() < 0,  'has-text-warning-dark' : newMarginPerc() >= 0}">
                                {{ newMarginPerc() | formatNumber }}%
                                </span>)
                            </div>                                                                
                            <div class="column has-text-right is-4">
                                <VeeInput
                                    :class="newMarginPerc() < 0 ? 'bad-price' : 'good-price'"
                                    refname="productPrice"
                                    ref="productPrice"
                                    type="currency" 
                                    v-model="scannedProduct.price"
                                    name="newPrice"
                                    :value="scannedProduct.price"
                                    :countryCurrencyCode="countryCurrencyCode"
                                    :editable="allowEdit && scannedProduct.upc != ''"
                                    :required="true"
                                    @input="calculateCost(scannedProduct.price)"
                                    />
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
    
            <footer class="modal-card-foot">
                <b-button 
                    id="intro-pbuf-save"
                    type="is-success has-text-weight-bold" 
                    :disabled="saveDisabled"
                    @click="saveUpdatedProduct">
                    {{ ( addingNewProduct ? "Add New Product" : "Update Product" ) }}
                </b-button>
                <b-button class="mr-3" type="is-default"  id="intro-pbuf-cancel"  @click="cancelForm">Cancel</b-button>
                <div  class="is-size-7 is-hidden-mobile">
                    <div v-if="addingNewProduct && tagActiveAddProduct"> 
                        <b-tag closable type="is-danger is-light"  @close="closeAddProductTag">
                            Product Not Found. Confirm Scanned Code is correct and Add New Product.
                        </b-tag>
                    </div>

                    <div v-if="tagActiveEnterCost"> 
                        <b-tag closable type="is-info is-light" @close="closeCostTag">
                            May enter Purchase Cost to calculate suggested selling price. Uses set department profit margin %.
                        </b-tag>
                    </div>
                </div>
            </footer>
    
        </div>
        </div>
      </section>
    </article>
</template>
    
    
<script>
    
    import UserStore from "../../../../store/UserStore"
    import VeeInput from "../../../../components/app/VeeInput.vue"
    // import * as introJs from '../../../../assets/introjs/intro.min.js'

    export default {
        components: {
            VeeInput,
        },
    
        props: {
             action: String,
             allowEdit: Boolean,
             isShowDialog: Boolean,
             scannedProduct: Object,
             addingNewProduct: Boolean,
             departments: Array,
             posConfig: Object
        },

        directives: {
            focus: {
                // directive definition
                inserted: function (el) {
                    el.focus()
                }
            }
        },
    
        data() {
            return {
                pageVersion: "1.0.1",
                pageName: "VeecliProductUpdateForm",
                tagActiveAddProduct: true,
                tagActiveEnterCost: true,
                trueValue: true,
                falseValue: false,
                zeroValue: 0,
                email: '',
                role: '',
                openOnFocus: true,
                keepFirst: true,
                clearable: false,
                data: [],
                isFetching: false,
                vendorName: '',
                employees: [],
                employeeName: '',
                employeeFirstName:'',
                name: '',
                page: 1,
                totalPages: 1,
                paymentMethod: "Cash",
                priceToCost: 0,
                countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
                user: [],
                taxRates: [],
                idChecks: [],
                introSteps: [
                    {
                        title: 'Update Product',
                        intro: 'Easy to use and powerful Product & Price Book update features.'
                    },
                    {
                        title: 'UPC',
                        element: '#intro-pbuf-upc',
                        intro: 'Scan UPC to add or edit Products.  Edit from list of Products. View Price Update history anytime.'
                    },
                    {
                        title: 'Product Name',
                        element: '#intro-pbuf-name',
                        intro: 'Enter or update Product Name.'
                    },
                    {
                        title: 'Department',
                        element: '#intro-pbuf-dept',
                        intro: 'Select Department. When changing department, defaults automatically applied if set in department. All products must have a department selected.'
                    },
                    {
                        title: 'Food Stamp',
                        element: '#intro-pbuf-foodstamp',
                        intro: 'Check if this product is eligible and allowed to use food stamp as payment.'
                    },
                    {
                        title: 'ID Check Required',
                        element: '#intro-pbuf-taxrates',
                        intro: 'Select the Tax Rates available.  Click 🗑 to clear all selected.'
                    },
                    {
                        title: 'ID Check Required',
                        element: '#intro-pbuf-idcheck',
                        intro: 'Select the ID Check available.  Click 🗑 to clear all selected.'
                    },
                    {
                        title: 'Calculated Cost',
                        element: '#intro-pbuf-calcost',
                        intro: 'Calculated cost at the margin percentage set for department. <br>Only shown when actual cost not available. May not highlight if field not visible.'
                    },
                    {
                        title: 'Suggested Price',
                        element: '#intro-pbuf-suggprice',
                        intro: 'Calculated suggested price at the margin percentage set for department. Only shown when actual cost is set. May not highlight if field not visible.'
                    },
                    {
                        title: 'Actual Cost',
                        element: '#intro-pbuf-cost',
                        intro: 'Enter the cost of the product.  Profit will be calculated on this cost.'
                    },
                    {
                        title: 'Current Price',
                        element: '#intro-pbuf-currprice',
                        intro: 'Current Price Set at POS.'
                    },
                    {
                        title: 'Retail Price',
                        element: '#intro-pbuf-price',
                        intro: 'Enter/Update Retail Price of the product.'
                    },
                    {
                        title: 'Save Changes',
                        element: '#intro-pbuf-save',
                        intro: 'Click button to save. Must change some value to save.'
                    },
                    {
                        title: 'Discard Changes',
                        element: '#intro-pbuf-cancel',
                        intro: 'Click on Cancel to discard all changes.'
                    },
                    {
                        title: 'Show Help Anytime',
                        element: '#intro-show-button-icon',
                        intro: 'Click on this icon to view the help popup anytime.'
                    }
                ]
            }
        },
    
        methods: {

            openDialog() {
                this.$nextTick(()=> {
                    let myref = this.$refs[`productPrice`].$refs[`productPrice`]
                    if (this.addingNewProduct) {
                        myref = this.$refs[`productName`].$refs[`productName`]
                        this.departmentChanged()
                    }

                    this.taxRates = this.scannedProduct.taxRatesIdArr
                    this.idChecks = this.scannedProduct.idCheckIdArr
                    this.calculateCost(this.scannedProduct.price)

                    this.showIntro(this.pageName,this.pageVersion,this.introSteps)
                    myref.focus()

                })
            },
    
            closeDialog() {
    
                this.$emit('cancel-product-form')
    
            },

            setTaxable() {
                if (this.scannedProduct.taxRatesIdArr == null) {
                    this.scannedProduct.idCheckIdArr = []
                    this.scannedProduct.isAgeValidation = false
                }
                if (this.scannedProduct.idCheckIdArr == null) {
                            this.scannedProduct.idCheckIdArr = []
                            this.scannedProduct.isAgeValidation = false
                        }
                if (this.scannedProduct.taxRatesIdArr.length == 0) {
                    this.scannedProduct.isTaxable = false
                } else {
                    this.scannedProduct.isTaxable = true
                }
            },
    
            cancelForm() {
                this.closeDialog()
            },

            saveUpdatedProduct() {
                if (this.getSetting('profitCalculationMethod')=='Markup') {
                    this.scannedProduct.suggestedSellPrice = this.suggestedMarkupPrice
                } else {
                    this.scannedProduct.suggestedSellPrice = this.suggestedMarginPrice
                }
                this.scannedProduct.taxRatesIdArr = this.taxRates
                this.scannedProduct.idCheckIdArr = this.idChecks
                this.$emit('save-updated-product')
            },

            calculateCost(price) {
                var cost = this.calculateMercandiseCost(price,this.deptProfitPercentage ) 
                this.priceToCost = cost
            },

            copyToPrice(type) {
                if (type=='markup') {
                    this.scannedProduct.price = this.suggestedMarkupPrice
                } else {
                    this.scannedProduct.price = this.suggestedMarginPrice
                }
                this.calculateCost(this.scannedProduct.price)
            },

            closeAddProductTag() {
                this.tagActiveAddProduct = false
            },

            closeCostTag() {
                this.tagActiveEnterCost = false
            },

            departmentChanged() {

                this.scannedProduct.foodStamp = this.scannedProduct.departments.allowFoodStamps
                this.scannedProduct.fractionalQty = this.scannedProduct.departments.allowFractionalQty
                this.scannedProduct.specialDiscount = this.scannedProduct.departments.allowSpecialDiscount
                this.scannedProduct.blueLawSysIds = this.scannedProduct.departments.blueLawSysIds
                this.scannedProduct.fees = this.scannedProduct.departments.fees
                this.scannedProduct.idCheckSysIds = this.scannedProduct.departments.idCheckSysIds
                this.scannedProduct.taxRateSysIds = this.scannedProduct.departments.taxRateSysIds
                
                // this.isTaxable = (this.scannedProduct.taxRateSysIds === "" ? 0 :1)
                
                if (!this.scannedProduct.taxRateSysIds) {
                    this.scannedProduct.taxRatesIdArr = []
                    this.isTaxable = false
                } else {
                    if (this.posConfig.taxRates) {
                        this.scannedProduct.taxRatesIdArr = this.sysIdListToIdArray(this.posConfig.taxRates,this.scannedProduct.taxRateSysIds)
                        this.isTaxable = true
                        if (this.scannedProduct.taxRatesIdArr == null) {
                            this.scannedProduct.taxRatesIdArr = []
                            this.isTaxable = false
                        }
                    } else {
                        this.scannedProduct.taxRatesIdArr = []
                        this.isTaxable = false
                    }
                }

                // this.isAgeValidation = (this.scannedProduct.idCheckSysIds === "" ? 0 :1)

                if (!this.scannedProduct.idCheckSysIds) {
                    this.scannedProduct.idCheckIdArr = []
                    this.scannedProduct.isAgeValidation = false
                } else {
                    if (this.posConfig.ageValidations) {
                        this.scannedProduct.idCheckIdArr = this.sysIdListToIdArray(this.posConfig.ageValidations,this.scannedProduct.departments.idCheckSysIds)
                        this.scannedProduct.isAgeValidation = true
                        if (this.scannedProduct.idCheckIdArr == null) {
                            this.scannedProduct.idCheckIdArr = []
                            this.scannedProduct.isAgeValidation = false
                        }
                    } else {
                        this.scannedProduct.idCheckIdArr = []
                        this.scannedProduct.isAgeValidation = false
                    }
                }

                this.idChecks = this.scannedProduct.idCheckIdArr
                this.taxRates = this.scannedProduct.taxRatesIdArr

                // this.taxableChanged()
                // this.ageValidationChanged()
                // console.log("IdCheck",this.scannedProduct.idCheckSysIds, this.scannedProduct.idCheckIdArr)

                this.scannedProduct.open = false                
            },

            clearIdChecks() {
                this.idChecks = []
                this.scannedProduct.idCheckSysIds = ""
                this.scannedProduct.idCheckIdArr = []
            },

            // ageValidationChanged() {
            //     console.log(this.scannedProduct.idCheckIdArr)
            //     if (!this.scannedProduct.isAgeValidation) {
            //         this.scannedProduct.idCheckSysIds = ""
            //         this.scannedProduct.idCheckIdArr = []
            //     }
            // },      
            
            clearTaxRates() {
                this.taxRates = []
                this.scannedProduct.taxRateSysIds = ""
                this.scannedProduct.taxRatesIdArr = []
            },

            // taxableChanged() {
            //     console.log(this.scannedProduct.isTaxable)
            //     if (!this.scannedProduct.isTaxable) {
            //         this.scannedProduct.taxRateSysIds = ""
            //         this.scannedProduct.taxRatesIdArr = []
            //     }
            // },

            currentMarginPerc() {
                if (!this.scannedProduct.averageCost) return 0
                if (!this.scannedProduct.departments) this.scannedProduct.departments = {}
                return this.calculateMerchandiseProfit(this.scannedProduct.averageCost,this.scannedProduct.priceCurrent,this.scannedProduct.departments.percentage)
            },

            newMarginPerc() {
                if (!this.scannedProduct.averageCost) return 0
                if (!this.scannedProduct.departments) this.scannedProduct.departments = {}
                return this.calculateMerchandiseProfit(this.scannedProduct.averageCost,this.scannedProduct.price,this.scannedProduct.departments.percentage)
            }
        },
    
        computed: {
            isDisabled() {
                return false
            },

            isFoodStampOk() {
                var foodStampOk = (this.scannedProduct.departments.storeMapping == "Grocery" || 
                    this.scannedProduct.departments.storeMapping == "InsideSales") 
                return foodStampOk
            },

            suggestedMarkupPrice() {
                return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarkupSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
            },

            suggestedMarginPrice() {
                return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarginSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
            },

            deptProfitPercentage() {
                return (this.scannedProduct && this.scannedProduct.departments ? this.scannedProduct.departments.percentage : 0)
            },
            
            suggestedPrice() {
                if (this.getSetting('profitCalculationMethod') === "Margin") {
                    return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarginSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
                } else {
                    return (this.scannedProduct && this.deptProfitPercentage ? this.merchMarkupSalesPrice(this.scannedProduct.averageCost, this.deptProfitPercentage) : 0)
                }
            },

            saveDisabled() {
                var okToSave = true
                if (!this.scannedProduct) {
                    okToSave = false
                }
                if (this.scannedProduct.price <= 0) {
                    okToSave = false
                }
                if (this.scannedProduct.name == this.scannedProduct.nameCurrent &&
                    this.scannedProduct.price == this.scannedProduct.priceCurrent &&
                    this.scannedProduct.averageCost == this.scannedProduct.costCurrent &&
                    this.scannedProduct.departments &&
                    this.scannedProduct.deptCurrent &&
                    this.scannedProduct.departments.id == this.scannedProduct.deptCurrent.id &&
                    this.scannedProduct.foodStamp == this.scannedProduct.foodStampCurrent &&
                    JSON.stringify(this.taxRates) == JSON.stringify(this.scannedProduct.taxRatesArrCurrent) &&
                    JSON.stringify(this.idChecks) == JSON.stringify(this.scannedProduct.idCheckIdArrCurrent)
                    ) {
                    okToSave = false
                }
                if ((this.scannedProduct.name == "")) {
                    okToSave = false
                }

                return !okToSave

            }
        },

        watch: {

            taxRates() {
                this.scannedProduct.taxRatesIdArr = this.taxRates
            },

            idChecks() {
                this.scannedProduct.idCheckIdArr = this.idChecks
            }
        },
    
        mounted() {
            this.user = this.getUser()            
        }
    
    }
</script>

<style>
.vertical-line {
  border-left: 2px solid lightgray;
  margin-top: 5px;
  /* height: 2500px; */
  margin-bottom: 5px;
}

.bottom-line {
    border-bottom: 0.5px solid gray;
    margin-left: 3px;
    margin-right: 3px;
}

.current-price {
    /* border-bottom: 0.5px solid gray; */
    height: 45px;
    box-shadow: 2px 2px gray;
    background-color:rgba(239, 239, 239, 0.945);
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-right: 3px;
    font-size: large;
}

.new-price {
    /* border-bottom: 0.5px solid gray; */
    height: 50px;
    box-shadow: 2px 2px gray;
    background-color:rgb(247, 240, 231);
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
    font-size: large;
}

.spacer-row {
    height: 2px;
}

.thin-button {
    height: 20px;
    width: 30px;
    border: none;
}

.bad-price input[type="text"]{
    color: rgb(172, 1, 1);
}

.bad-price {
    color:  rgb(172, 1, 1);
}
.good-price {
    color: green;
}

/* .multi-select option {
  color: #fff;
  background: #308cee -webkit-linear-gradient(bottom, #72aae6 0%, #308cee 100%);
  margin-bottom: 1px;
} */

.multi-select option:checked {
  color: #fff;
  background: #308cee -webkit-linear-gradient(bottom, #308cee 0%, #308cee 100%);
  margin-bottom: 1px;
}

</style>